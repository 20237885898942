<template>
    <div v-if="loading == false">
        <ChirpList
            :data_source="rows"
            :totalCount="totalCount"
            :loadingColumns="loadingTable"
            :storeKey="storeKey"
            :useOverride="useOverride"
            :column_meta="columnMeta"
            @cancelOverride="handleCancelOverride"
            @rowSelected="dynamic_target"
        />
    </div>
    <div v-else>Loading...</div>
</template>

<script>
    import { tryGetFilter } from '@/util/tryGetFilter';

    const api_root = 'notes';

    import ChirpList from '@/components/general/list/ChirpList';
    import qs from 'qs';

    export default {
        name: 'Notes',
        components: { ChirpList },
        props: {
            overrideFilter: {
                type: Object,
                default: null
            }
        },
        data() {
            return {
                rows: [],
                totalCount: 0,
                targetPage: 'NoteDetail',
                loading: true,
                loadingTable: false,
                useOverride: false,
                columnMeta: [
                    { field: 'date', headerText: 'Date' },
                    { field: 'note', headerText: 'Note' },
                    { field: 'tags', headerText: 'Tags' },
                    { field: 'client', headerText: 'Client' },
                    { field: 'note_type', headerText: 'Note Type' },
                    { field: 'active', hide: 1 },
                    {
                        field: 'diags',
                        hide: 1
                    },
                    { field: 'goals', hide: 1 },
                    { field: 'objectives', hide: 1 },
                    { field: 'interventions', hide: 1 },
                    { field: 'summary', hide: 1 },
                    { field: 'recommends', hide: 1 }
                ],
                criteriaProps: {}
            };
        },
        computed: {
            criteria() {
                return this.useOverride && this.overrideFilter ? this.overrideCriteria : this.savedCriteria;
            },
            savedCriteria() {
                return tryGetFilter(this.$store, this.storeKey);
            },
            overrideCriteria() {
                return this.$store.getters['filters/overrideCriteria'](this.storeKey);
            },
            storeKey() {
                if(this.$route.query.storeKey){
                    return this.$route.query.storeKey;
                }
                else if(this.pageMeta?.page){
                    return this.pageMeta.page;
                }
                else{
                    return 'notes';
                }
            }
        },
        methods: {
            async load_data() {
                const body = {
                    criteria: this.criteria
                };
                const res = await this.$api.post(`/${api_root}`, body);
                this.rows = res.data.rows;

                this.totalCount = res.data.total_count;
                this.$nextTick(() => {
                    this.loading = false;
                });
            },
            async init() {
                let { override, overrideFilter, storeKey } = this.$route.query ?? {};
                if(override && overrideFilter && storeKey){
                    this.useOverride = true;
                    overrideFilter = qs.parse(overrideFilter);
                    this.overrideFilter = overrideFilter;
                    this.$store.commit('filters/applyOverride', {
                        stateKey: storeKey,
                        criteria: this.overrideFilter
                    })
                }
                else if (this.overrideFilter) {
                    this.useOverride = true;
                    this.$store.commit('filters/applyOverride', {
                        stateKey: this.storeKey,
                        criteria: this.overrideFilter
                    });
                }
                await this.load_data();
            },
            async dynamic_target(row) {
                if (row.note_type == 'progress') {
                    this.$router.push({ name: 'ProgressNote', params: { id: row.id } });
                }
                if (row.note_type == 'chart') {
                    this.$router.push({ name: 'ChartNote', params: { id: row.id } });
                }
                // this.$router.push({ name: row.component_name, params: { record_id: row.id } });
            },
            async modifyCriteria(criteria) {
                if (this.targetPage) {
                    this.$store.commit('filters/update', {
                        criteriaPage: this.targetPage,
                        payload: criteria
                    });
                }
                this.criteriaProps = criteria;
                this.load_data();
            },
            handleCancelOverride() {
                this.useOverride = false;
            }
        },
        watch: {
            criteria: {
                deep: true,
                async handler() {
                    await this.load_data();
                }
            }
        },
        async created() {
            await this.init();
        }
    };
</script>
